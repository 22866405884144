<template>
  <div class="documents-pages">
    <Header/>
    <div class="documents-pages__content">
      <router-view/>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/uniq/header/Header';
import './style.scss';

export default {
  components: {
    Header,
    Footer: () => import('@/components/uniq/footer/Footer'),
  }
}
</script>